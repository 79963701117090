import styles from "./ProfileSearch.module.css";
import Image from "next/image";

export interface SearchParticipantTemp {
  id: string;
  name: string;
  profileUrl?: string;
  color: string;
}

interface ProfileSearchProps {
  participant: SearchParticipantTemp;
}

export function ProfileSearch({ participant }: ProfileSearchProps) {
  return (
    <div className={styles.container}>
      <div
        className={styles.profile}
        style={{ backgroundColor: participant ? participant.color : "#6F23FF" }}
      >
        {participant.profileUrl === undefined ? undefined : (
          <div style={{ position: "relative", zIndex: 0 }}>
            <Image
              src={participant.profileUrl || ""}
              alt="avator"
              width={32}
              height={32}
              objectFit="cover"
            />
          </div>
        )}
        <span
          className={styles.name}
          style={{
            display: participant
              ? participant.profileUrl
                ? "none"
                : "block"
              : "none",
          }}
        >
          {participant ? participant.name.charAt(0) : ""}
        </span>
      </div>
    </div>
  );
}
