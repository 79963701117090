import React, { useState } from "react";
import {
  Drawer,
  Button,
  Popover,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  IconButton,
} from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import CloseIcon from "@mui/icons-material/Close";
import useApi from "lib/useApi";
import patchAPI from "lib/patchApi";
import postAPI from "lib/postApi";
import deleteAPI from "lib/deleteApi";
import { useLocalStorage } from "contexts/LocalStorageContext";
import { title } from "helpers/helpers";

type PropertyDefinition = {
  openPropertyDrawer: boolean;
  setOpenPropertyDrawer: (open: boolean) => void;
};

const assignableToOptions = ["member", "crowd", "organization", "campaign"];
const categoryOptions = [
  "text",
  "integer",
  "date",
  "boolean",
  "single_select",
  "birthday_day",
  "birthday_month",
  "unique_text",
  "unique_integer",
  "unique_list",
  "multi_select",
];

const PropertyDrawer: React.FC<PropertyDefinition> = ({
  openPropertyDrawer,
  setOpenPropertyDrawer,
}) => {
  const { orgId, staff, admin } = useLocalStorage();
  const { data, mutate, isLoading } = useApi(
    `/api/orgs/${orgId}/property_definitions`,
    staff
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverType, setPopoverType] = useState<"create" | "patch" | null>(
    null
  );
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [formValues, setFormValues] = useState({
    name: "",
    field: "",
    description: "",
    assignable_to: "",
    category: "",
    options: [] as string[],
  });
  const [newOption, setNewOption] = useState<string>("");

  const openPopover = (
    event: React.MouseEvent<HTMLElement>,
    type: "create" | "patch",
    id: string | null = null
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverType(type);
    setCurrentId(id);
    if (type === "patch" && id) {
      const property = data?.find((item) => item.id === id);
      if (property) {
        setFormValues({
          name: property.name,
          field: property.field,
          description: property.description,
          assignable_to: property.assignable_to,
          category: property.category,
          options: property.options || [],
        });
      }
    }
  };

  const closePopover = () => {
    setAnchorEl(null);
    setPopoverType(null);
    setFormValues({
      name: "",
      field: "",
      description: "",
      assignable_to: "",
      category: "",
      options: [],
    });
    setNewOption("");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleOptionInputChange = (index: number, value: string) => {
    const newOptions = [...formValues.options];
    newOptions[index] = value;
    setFormValues({ ...formValues, options: newOptions });
  };

  const handleAddOptionToForm = () => {
    if (newOption) {
      setFormValues({
        ...formValues,
        options: [...formValues.options, newOption],
      });
      setNewOption("");
    }
  };

  const handleRemoveOptionFromForm = (index: number) => {
    const newOptions = formValues.options.filter((_, i) => i !== index);
    setFormValues({ ...formValues, options: newOptions });
  };

  const handleCreate = async () => {
    try {
      await mutate(async (currentData) => {
        const newProperty = await postAPI(
          `/api/orgs/${orgId}/property_definitions`,
          {
            property_definition: formValues,
          }
        );
        return [...(currentData || []), newProperty];
      }, false);
      closePopover();
    } catch (error) {
      console.error(error);
    }
  };

  const handlePatch = async () => {
    if (!currentId) return;
    try {
      await mutate(async (currentData) => {
        await patchAPI(`/api/orgs/${orgId}/property_definitions/${currentId}`, {
          property_definition: formValues,
        });
        return currentData?.map((item) =>
          item.id === currentId ? { ...item, ...formValues } : item
        );
      }, false);
      closePopover();
    } catch (error) {
      console.error(error);
    }
  };

  // const handleDelete = async (id: string) => {
  //   try {
  //     await mutate(async (currentData) => {
  //       await deleteAPI(`/api/orgs/${orgId}/property_definitions/${id}`);
  //       return currentData?.filter((item) => item.id !== id);
  //     }, false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

//   const handleAddOption = async () => {
//     if (!newOption || !currentId) return;
//     try {
//       await mutate(async (currentData) => {
//         await postAPI(
//           `/api/orgs/${orgId}/property_definitions/${currentId}/add_option?option=${newOption}`, {}
//         );
//         const updatedProperty = currentData?.find(
//           (item) => item.id === currentId
//         );
//         if (updatedProperty) {
//           updatedProperty.options = [
//             ...(updatedProperty.options || []),
//             newOption,
//           ];
//         }
//         return currentData;
//       }, false);
//       setNewOption("");
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleRemoveOption = async (option: string) => {
//     if (!currentId) return;
//     try {
//       await mutate(async (currentData) => {
//         await postAPI(
//           `/api/orgs/${orgId}/property_definitions/${currentId}/remove_option?option=${option}`, {}
//         );
//         const updatedProperty = currentData?.find(
//           (item) => item.id === currentId
//         );
//         if (updatedProperty) {
//           updatedProperty.options = updatedProperty.options?.filter(
//             (opt) => opt !== option
//           );
//         }
//         return currentData;
//       }, false);
//     } catch (error) {
//       console.error(error);
//     }
//   };

  return (
    <Drawer
      anchor="right"
      open={openPropertyDrawer}
      onClose={() => setOpenPropertyDrawer(false)}
      sx={{ zIndex: 10000 }}
    >
      <Stack spacing={1} sx={{ width: 600, padding: 2 }}>
        <Button
          className="tailwind-button-green"
          onClick={(e) => openPopover(e, "create")}
        >
          Add Property Definition
        </Button>
        <SimpleTreeView>
          {data?.map((property) => (
            <TreeItem
              sx={{ mb: 1 }}
              key={property.id}
              itemId={property.id}
              label={property.name}
            >
              <TreeItem
                itemId={`${property.id}-field`}
                label={`Field: ${property.field}`}
              />
              <TreeItem
                itemId={`${property.id}-description`}
                label={`Description: ${property.description}`}
              />
              <TreeItem
                itemId={`${property.id}-assignable`}
                label={`Assignable to: ${property.assignable_to}`}
              />
              <TreeItem
                itemId={`${property.id}-category`}
                label={`Category: ${property.category}`}
              />
              {property.options && (
                <TreeItem itemId={`${property.id}-options`} label="Options">
                  {property.options.map((option, index) => (
                    <TreeItem
                      key={index}
                      itemId={`${property.id}-option-${index}`}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span>{option}</span>
                          {/* <IconButton size="small" onClick={() => handleRemoveOption(option)}>
                            <CloseIcon fontSize="small" />
                          </IconButton> */}
                        </Stack>
                      }
                    />
                  ))}
                  {/* <TreeItem
                    itemId={`${property.id}-add-option`}
                    label={
                      <Stack direction="row" spacing={1} alignItems="center">
                        <TextField
                          size="small"
                          value={newOption}
                          onChange={(e) => setNewOption(e.target.value)}
                          placeholder="New option"
                        />
                        <IconButton size="small" onClick={handleAddOption}>
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    }
                  /> */}
                </TreeItem>
              )}
              <Stack direction="row" spacing={1} mt={2} mb={2}>
                <Button
                  className="tailwind-button"
                  onClick={(e) => openPopover(e, "patch", property.id)}
                >
                  Edit
                </Button>
                {/* <Button
                  className="tailwind-button-red"
                  onClick={() => handleDelete(property.id)}
                >
                  Delete
                </Button> */}
              </Stack>
            </TreeItem>
          ))}
        </SimpleTreeView>
      </Stack>
      <Popover
        sx={{ zIndex: 10001 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, width: 500 }}>
          <TextField
            name="name"
            label="Name"
            value={formValues.name}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            name="field"
            label="Field"
            value={formValues.field}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            name="description"
            label="Description"
            value={formValues.description}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          {title("Assignable to")}
          <RadioGroup
            name="assignable_to"
            row
            value={formValues.assignable_to}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          >
            {assignableToOptions.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
          {title("Category")}
          <RadioGroup
            name="category"
            row
            value={formValues.category}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          >
            {categoryOptions.map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>

          {(formValues.category === "single_select" ||
            formValues.category === "multi_select") && (
            <Stack spacing={1}>
              {title("Options")}
              {formValues.options.map((option, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <TextField
                    size="small"
                    value={option}
                    onChange={(e) =>
                      handleOptionInputChange(index, e.target.value)
                    }
                  />
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveOptionFromForm(index)}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Stack>
              ))}
              <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                  size="small"
                  value={newOption}
                  onChange={(e) => setNewOption(e.target.value)}
                  placeholder="New option"
                />
                <IconButton size="small" onClick={handleAddOptionToForm}>
                  <AddIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          )}

          <Button
            className="tailwind-button-green"
            sx={{ mt: 2 }}
            onClick={popoverType === "create" ? handleCreate : handlePatch}
          >
            {popoverType === "create" ? "Create" : "Update"}
          </Button>
        </Box>
      </Popover>
    </Drawer>
  );
};

export default PropertyDrawer;
