import React, { ElementType, useEffect } from "react";
import styles from "./Dashboard.module.css";
import { Box, Tabs, Tab, IconProps, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import PrimarySearchAppBar from "./AppBar";
import Background from "/assets/images/scback.png";
import OrgSelector from "components/tailwind_mui/OrgSelector";
import { useRouter } from "next/router";
import { useLocalStorage } from "contexts/LocalStorageContext";
import { FontAwesomeIcon } from "helpers/helpers";

interface DashboardProps {
  children?: React.ReactNode;
  handleClickAccount: (param: any) => void;
  handleClickNotification: (param: any) => void;
  openAccount: boolean;
  handleClickAdd: (param: any) => void;
  handleChangeSearch: (param: any) => void;
  searchDivRef?: any;
  userData?: any;
  drawerItems?: any;
}

function DashboardMobile({
  children,
  handleClickAccount,
  openAccount,
  handleClickNotification,
  userData,
  handleChangeSearch,
  searchDivRef,
  drawerItems,
}: DashboardProps) {
  const { windowSize } = useLocalStorage();
  const router = useRouter();
  useEffect(() => {
    switch (router.pathname) {
      case "/dashboard":
        setValue(0);
        break;
      case "/socialcrowds":
        setValue(1);
        break;
      case "/socialcrowds/[id]":
        setValue(1);
        break;
      case "/campaigns":
        setValue(2);
        break;
      case "/campaigns/[id]":
        setValue(2);
        break;
      case "/pointsandrewards":
        setValue(3);
        break;
      case "/settings":
        setValue(4);
        break;
    }
  }, []);

  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        router.push("/dashboard");
        break;
      case 1:
        router.push("/socialcrowds");
        break;
      case 2:
        router.push("/campaigns");
        break;
      case 3:
        router.push("/pointsandrewards");
        break;
    }
  };

  const stylesBackground = {
    paperContainer: {
      height: "100%",
      background: "rgba(188,189,225,.2)",
      backgroundImage: `url(${Background.src})`,
      width: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center top",
    },
  };

  return (
    <Box className={`${styles.mobile}`} style={{ height: windowSize[1] }}>
      <Box width="100%" height={60}>
        <PrimarySearchAppBar
          handleChangeSearch={handleChangeSearch}
          searchDivRef={searchDivRef}
          handleClickAccount={handleClickAccount}
          userData={userData}
          openAccount={openAccount}
          handleClickNotification={handleClickNotification}
        />
      </Box>
      <Box
        width="100%"
        height="100%"
        pb={"50px"}
        style={stylesBackground.paperContainer}
        sx={{ overflow: "auto" }}
      >
        <Stack width="100%" alignItems="center">
          <OrgSelector />
        </Stack>
        <TabPanel drawerItems={drawerItems} value={value}>
          {children}
        </TabPanel>
      </Box>
      <Box width="100%">
        <StyledTabs value={value} variant="fullWidth" onChange={handleChange}>
          <StyledTab
            icon={
              <FontAwesomeIcon
                icon="gauge-high"
                size="1"
                sx={{ fontSize: "1.3rem" }}
              />
            }
          />
          <StyledTab
            icon={
              <FontAwesomeIcon
                icon="user-group-simple"
                size="1"
                sx={{ fontSize: "1.3rem" }}
              />
            }
          />
          <StyledTab
            icon={
              <FontAwesomeIcon
                icon="poll-people"
                size="1"
                sx={{ fontSize: "1.3rem" }}
              />
            }
          />
          <StyledTab
            icon={
              <FontAwesomeIcon
                icon="trophy-star"
                size="1"
                sx={{ fontSize: "1.3rem" }}
              />
            }
          />
        </StyledTabs>
      </Box>
    </Box>
  );
}
export default DashboardMobile;
interface TabPanelProps {
  children?: React.ReactNode;
  index?: number;
  value: number;
  drawerItems?: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, drawerItems, ...other } = props;
  const [activateTab, setActivateTab] = React.useState(false);

  setTimeout(() => {
    setActivateTab(true);
  }, 100);

  const mainIndex = drawerItems?.findIndex((item: any, i) => i === value);

  return (
    <div
      role="tabpanel"
      hidden={value !== mainIndex}
      id={`simple-tabpanel-${mainIndex}`}
      aria-labelledby={`simple-tab-${mainIndex}`}
      {...other}
    >
      {value === mainIndex && activateTab && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}
interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  variant: "fullWidth" | "scrollable" | "standard";
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  component?: ElementType<any>;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    // backgroundColor: "#635ee7",
  },
});

interface StyledTabProps {
  label?: string;
  icon: React.ReactElement<IconProps>;
  onClick?: () => void;
}

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(
  ({ theme }) => ({
    minWidth: 0,
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    // color: "black",
    "&.Mui-selected": {
      color: "rgba(100, 95, 228, 1)",
      fill: "rgba(100, 95, 228, 1)",
      backgroundColor: "rgba(100, 95, 228, 0.1)",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0)",
    },
  })
);
