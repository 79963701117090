"use server";
import styles from "./index.module.css";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Image from "next/image";
import { Box, CircularProgress, Stack, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import NewPng from "/assets/images/new-icon.png";
import SocialCrowdIcon from "/assets/logo/SocialCrowdIcon_new.png";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import useApi from "lib/useApi";
import { useLocalStorage } from "contexts/LocalStorageContext";

export default withPageAuthRequired(function CustomizedSelects({ user }) {
  const { staff, admin, orgId } = useLocalStorage();
  const router = useRouter();

  const localStorage = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };

  const {
    data: orgs,
    mutate,
    error: orgsError,
  } = useApi(`/api/orgs/my_orgs`, true);

  const { data: memberData } = useApi(
    `/api/orgs/${orgId}/members/my_member`,
    true
  );

  const [value, setValue] = useState(orgId?.toString()); // with session

  useEffect(() => {
    window.localStorage.setItem("orgSelected", value);
  }, [value]);

  const orgIds = new Set(orgs?.map((item) => item?.id));
  const orgsExist = () => {
    if (localStorage()) {
      const alreadyId = localStorage()?.orgSelected;
      return orgIds.has(alreadyId);
    }
  };

  useEffect(() => {
    if (orgsExist()) {
      setValue(localStorage()?.orgSelected);
    }
    if (
      value === "" ||
      value === undefined ||
      !orgsExist() ||
      orgs === undefined
    ) {
      mutate();
      setTimeout(() => {
        if (orgs) {
          setValue(orgs[0]?.id);
        }
      }, 1500);
      if (value === "" || value === undefined) {
        // refresh page
        window.location.reload();
      }
    }
  }, [orgs]);

  const [data, setData] = useState([
    {
      id: orgId as any,
      name: "Syncing..." as any,
      src: NewPng as any,
      new: false as any,
      profile: true as any,
    },
  ]);

  useEffect(() => {
    if (orgs) {
      setData(
        orgs?.map((item: any) => {
          return { ...item, src: item?.logo_urls[0] || SocialCrowdIcon };
        })
      );
    }
  }, [orgs]);

  const handleChange = (event: { target: { value: string } }) => {
    setValue(event.target.value);
    window.localStorage.setItem(
      "admin",
      memberData?.role === "admin" ? "true" : "false"
    );
    window.localStorage.removeItem("crowdSelected");
    router.push("/dashboard");
  };

  return (
    <div>
      <FormControl sx={{ m: 2 }} variant="standard">
        <Select
          IconComponent={KeyboardArrowDownIcon}
          value={value}
          defaultValue=""
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {data?.map((item) => {
            return (
              <MenuItem
                key={item.id}
                value={item.id}
                className={
                  item?.id?.toString() === value?.toString()
                    ? styles.hidden
                    : ""
                }
              >
                <Stack
                  mr={1}
                  flexDirection="row"
                  alignItems="center"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack alignItems="center" justifyContent="center">
                    {data[0].name !== "Syncing..." ? (
                      <Image
                        src={item?.src}
                        alt="Company Logo"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <CircularProgress size={10} />
                    )}
                  </Stack>

                  <Box
                    style={{
                      marginLeft: 10,
                      width: "100%",
                    }}
                  >
                    {item.name}
                  </Box>
                </Stack>{" "}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  width: 300,
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "&& > svg": {
    // color: "#000",
    width: "1.7em",
  },
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #866baa42",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      borderColor: "#ac7ebc",
      boxShadow: "0 0 0 0.2rem #cbbcd73e",
    },
  },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
